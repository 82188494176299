import GolfErpAPI from "@/api/v2/GolfErpAPI";
export const ROOT_PATH = '/stock-facility-mng';

class StockFacilityMng {

    /** 시설 수리내역 조회 */
    async getFcltyRepairHdr(params) {
        const {data} = await GolfErpAPI.http.get(`${ROOT_PATH}/repair/list` ,{params} );
        return data;
    }
    /** 시설 수리내역 조회 VO */
    async getFcltyRepairHdrVO(params) {
        const {data} = await GolfErpAPI.http.get(`${ROOT_PATH}/repair/listVO` ,{params} );
        return data;
    }
    /** 시설 수리내역 상세 조회 */
    async getFcltyRepairDetail(params) {
        const {data} = await GolfErpAPI.http.get(`${ROOT_PATH}/repair/Detail` ,{params} );
        return data;
    }
    /** 시설 수리내역 상세 등록 */
    async postFcltyRepairDetail(params) {
        const {data} = await  GolfErpAPI.http.post(`${ROOT_PATH}/repair/Detail` ,params);
        return data;
    }
    /** 시설 수리내역 상세 수정 */
    async patchFcltyRepairDetail(params) {
        const {data} = await  GolfErpAPI.http.post(`${ROOT_PATH}/repair/Detail` ,params);
        return data;
    }
    /** 시설 수리내역 상세 삭제 */
    async deleteFcltyRepairDetail(params) {
        const {data} = await GolfErpAPI.http.delete(`${ROOT_PATH}/repair/Detail` ,{params} );
        return data;
    }
    /** 시설 출고 품목 조회 */
    async getFcltyRepairDtlList(params) {
        const {data} = await GolfErpAPI.http.get(`${ROOT_PATH}/repair/DtlList` ,{params} );
        return data;
    }

}

export default new StockFacilityMng();