<template>
  <div class="content-wrapper">
    <!-- REPORT -->
    <report-view ref="reportViewComponent" :isPopup="true"/>
    <!-- 필터영역 -->
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">수리일자</div>
            <ul class="content">
              <li class="item dateRange">
                <input-date-range v-model="searchFilterOptions.dateRange"/>
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">시설구분</div>
            <ul class="content">
              <li class="item">
                <ejs-dropdownlist
                    ref="searchfcltyDiv"
                    v-model="searchFilterOptions.fcltyDiv"
                    :dataSource="commonCodeOptions.fcltyDiv"
                    :allowFiltering="false"
                    :fields="commonCodeOptions.fields"
                    cssClass="lookup-condition-dropdown"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">시설명</div>
            <ul class="content">
              <li class="item">
                <input-text ref="searchfcltyName" v-model="searchFilterOptions.fcltyName" @keydown.enter="onClickSearch"/>
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button button-div="GET" :is-shortcut-button="true" :ignore="isPopupOpened" @click.native="onClickSearch">조회</erp-button>
        </div>
      </div>

    </div>
    <!-- 본문 -->
    <div class="content-body">
      <article class="body-article" :class="isOpen ? 'detail-view-opened' : ''">
        <!-- ######## 목록 리스트 ########-->
        <div class="article-left">
          <section class="article-section">
            <div class="section-header">
              <div class="header-left">
                <div class="header-title">{{gridField.title}}</div>
                <div class="header-caption">[ {{ gridField.count }} 건 ]</div>
              </div>
              <div class="header-right">
                <ul class="header-button">
                  <li class="add">
                    <erp-button button-div="GET" :is-icon-custom="true" @click.native="onClickAdd">추가</erp-button>
                  </li>
                  <li class="print">
                    <erp-button button-div="FILE" @click.native="onClickExcel">Excel</erp-button>
                  </li>
                </ul>
              </div>
            </div>
            <div class="section-body">
              <div class="body-grid">
                <ejs-grid-wrapper
                    ref="grid"
                    v-bind ="initGrid"
                    @recordClick="onGridClick"
                    @queryCellInfo="onQueryCellInfo"
                    @headerCellInfo="onHeaderCellInfo"
                    @actionComplete="gridComplete"
                />
              </div>
            </div>
          </section>
        </div>
        <!-- ######## 상세본문 ########-->
        <div class="article-right">
          <section class="article-section" >
            <div class="section-header">
              <div class="header-left">
                <div class="header-title">수리상세</div>
              </div>
              <div class="header-right">
                <ul class="header-button">
                  <li class="save keyColor">
                    <erp-button button-div="SAVE" :is-key-color="true" :is-shortcut-button="true" :ignore="!isOpen || isPopupOpened" @click.native="onClickSave">저장</erp-button>
                  </li>
                  <li class="delete">
                    <erp-button v-if="!isNew" button-div="DELETE" :is-key-color="true" :is-shortcut-button="true" :ignore="!isOpen || isPopupOpened" @click.native="onClickDelete">삭제</erp-button>
                  </li>
                  <li class="close">
                    <erp-button button-div="CLOSE" @click.native="onClickClose">닫기</erp-button>
                  </li>
                </ul>
              </div>
            </div>
            <div class="section-body">
              <div class="article-section" style="height: 180px;">
                <div class="section-body" style="border: none;">
                  <article class="article body">
                    <div class="body-data">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li class="field">
                              <div class="title">수리번호</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-text ref="repairId" id="repairId" v-model="detailData.repairId" :readonly="true"/>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title required">수리일자</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-date format="YYYY-MM-DD" ref="repairDate" id="repairDate"  v-model="detailData.repairDate" :isBindingEmptyString="true" type="body-data" :disabled="!isNew"/>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title required">시설명</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group input" style="width: 90%">
                                    <div class="form">
                                      <input-text ref="fcltyName" id="fcltyName" v-model="detailData.fcltyName" @keydown.enter="onClickPopupSearch()"/>
                                    </div>
                                  </li>
                                  <li class="item form-group button" style="width: 10%">
                                    <ul class="button">
                                      <li class="search">
                                        <erp-button button-div="GET" :ignore="isPopupOpened" @click.native="onClickPopupSearch()">검색</erp-button>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">시설구분</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-dropdownlist
                                          ref="fcltyDiv"
                                          v-model="detailData.fcltyDiv"
                                          :dataSource="commonCodeOptions.fcltyDiv"
                                          :allowFiltering="false"
                                          :fields="commonCodeOptions.fields"
                                          cssClass="lookup-condition-dropdown"
                                          :readonly="true"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">점검부</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-dropdownlist
                                          ref="chckPart"
                                          v-model="detailData.chckPart"
                                          :dataSource="commonCodeOptions.fcltyChckPart"
                                          :allowFiltering="false"
                                          :fields="commonCodeOptions.fields"
                                          cssClass="body-data-dropdown"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">수리구분</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-dropdownlist
                                          ref="repairDiv"
                                          v-model="detailData.repairDiv"
                                          :dataSource="commonCodeOptions.fcltyRepairPart"
                                          :allowFiltering="false"
                                          :fields="commonCodeOptions.fields"
                                          cssClass="body-data-dropdown"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">등록자</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-text ref="insertName" id="insertName" v-model="detailData.insertName" :readonly="true"/>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">고장부위</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-dropdownlist
                                          ref="defectRegn"
                                          v-model="detailData.defectRegn"
                                          :dataSource="commonCodeOptions.fcltyDefectRegn"
                                          :allowFiltering="false"
                                          :fields="commonCodeOptions.fields"
                                          cssClass="body-data-dropdown"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field remarks voc-section">
                              <div class="title">수리내역</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-textarea ref="remarks" name="remarks" v-model="detailData.remarks"/>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
              </div>
              <div class="article-section" style="height:calc(100% - 180px)">
                <div class="section-body" style="border: none;">
                  <div class="article-section">
                    <div class="section-header">
                      <div class="header-left">
                        <div class="header-title">출고품목</div>
                        <div class="header-caption">[ {{ gridField2.count }} 건 ]</div>
                      </div>
                      <div class="header-right">
                        <ul class="header-button">
                          <li class="add">
                            <erp-button button-div="SAVE" :is-icon-custom="true" @click.native="onClickPopupSearch2">행추가</erp-button>
                          </li>
                          <li class="delete">
                            <erp-button button-div="DELETE" @click.native="onClickListDel">행삭제</erp-button>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="section-body" style="border: none;">
                      <div class="body-grid table-side">
                        <ejs-grid-wrapper
                            ref="grid2"
                            v-bind ="initGrid2"
                            @queryCellInfo="onQueryCellInfo2"
                            @headerCellInfo="onHeaderCellInfo2"
                            @actionComplete="gridComplete2"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </article>
    </div>
    <!-- 팝업 -->
    <fclty-search-popup
        ref="fcltySelectPopup"
        v-if="isShowSearchPopup"
        @popupConfirmed="onPopupConfirmed"
    />
    <supplies-stock-search-popup
        ref="suppliesStockSearchPopup"
        v-if="isShowSearchPopup2"
        @popupConfirmed="onPopupConfirmed2"
    />
  </div>
</template>

<script>

import commonMixin from "@/views/layout/mixin/commonMixin";
import ReportView from "@/components/common/report/ReportView.vue";
import ErpButton from "@/components/button/ErpButton.vue";
import EjsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import {ExcelExport, Filter, ForeignKey, Page, Resize, Edit} from "@syncfusion/ej2-vue-grids";
import MessagePopupDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";

import {commonCodesGetCommonCode} from "@/utils/commonCodes";
import InputText from "@/components/common/text/InputText.vue";
import InputDate from "@/components/common/datetime/InputDate.vue";
import InputTextarea from "@/components/common/text/InputTextarea.vue";
import InputDateRange from "@/components/common/datetime/InputDateRange.vue";
import moment from "moment/moment";
import {compareDate, getFormattedDate} from "@/utils/date";
import {validateFormRefs} from "@/utils/formUtil";
import StockFacilityMng from "@/api/v2/StockManage/StockFacilityMng";
import fcltySearchPopup from "@/views/stock-management/common/FacilitySearchPopup.vue";
import suppliesStockSearchPopup from "@/views/stock-management/common/SuppliesStockSearchPopup.vue";
import {mapGetters} from "vuex";

export default {
  name: "FacilityRepairRegistration",
  mixins: [commonMixin, MessagePopupDialogMixin],
  components: {
    ReportView,
    ErpButton,
    EjsGridWrapper,
    InputText,
    InputDate,
    InputTextarea,
    InputDateRange,
    fcltySearchPopup,
    suppliesStockSearchPopup,
  },

  /**
   * ##################################### 데이터 필드 정의 ########################################
   */
  data() {
    return {
      //신규,수정 여부
      isNew: false,
      //팝업 오픈 여부
      isOpen: false,
      //검색 팝업 오픈 여부
      isShowSearchPopup: false,
      isShowSearchPopup2: false,

      //조회 필드
      searchFilterOptions: {
        fcltyName: "",
        fcltyDiv: "",
        dateRange: {
          //from: getAddDays(new Date(),-30),
          from: moment().startOf('month').format('YYYY-MM-DD'),
          to: moment().format('YYYY-MM-DD'),
        },
      },

      //드롭다운 코드
      commonCodeOptions: {
        fcltyDiv: commonCodesGetCommonCode("FCLTY_DIV",false,true,"",""),
        fcltyChckPart: commonCodesGetCommonCode("FCLTY_CHCK_PART",false,true,"",""),
        fcltyRepairPart: commonCodesGetCommonCode("FCLTY_REPAIR_PART",false,true,"",""),
        fcltyDefectRegn: commonCodesGetCommonCode("FCLTY_DEFECT_REGN",false,true,"",""),
        fields: { text: "comName", value: "comCode" },
      },

      //정합성 체크 항목
      validateRefField: {
        "repairDate": { required: true },
        "fcltyName": { required: true },
      },

      //그리드 필드
      gridField: {
        title: "수리목록",
        count : 0,
        dataSource: [],
      },
      gridField2: {
        title: "출고품목",
        count : 0,
        dataSource: [],
      },

      //상세 데이터 필드
      detailData: {
        repairId: null,
        repairDate: null,
        fcltyDiv: null,
        fcltyCode: null,
        fcltyName: null,
        chckPart: null,
        repairDiv: null,
        defectRegn: null,
        remarks: null,
        delFlag: true,
        insertName: null,
        // 출고품목 저장용 리스트 구조체
        dtlListD: null,
        dtlListU: null,
        dtlListI: null,
      },
    };
  },

  /**
   * ##################################### 그리드 정의 ########################################
   */
  computed: {
    ...mapGetters(["userIp","userId","username"]),
    // 팝업시 메인화면 버튼의 단축키 조정 , 팝업이 없으면 false 리턴
    isPopupOpened() {
      return (
          this.isShowSearchPopup || this.isShowSearchPopup2
      );
    },
    initGrid() {
      return {
        columns: [
          { field: "repairId",       textAlign: 'center', allowEditing: false, width: 70,   visible: true,  type: "string",  headerText: "수리번호", isPrimaryKey: true, },
          { field: "repairDate",     textAlign: 'center', allowEditing: false, width: 100,  visible: true,  type: "string",  headerText: "수리일자",
            valueAccessor: (field,data) => getFormattedDate(data[field]) },
          { field: "fcltyDiv",       textAlign: 'center', allowEditing: false, width: 100,  visible: true,  type: "string",  headerText: "시설구분", isCommonCodeField: true, dataSource: this.commonCodeOptions.fcltyDiv, },
          { field: "fcltyCode",      textAlign: 'center', allowEditing: false, width: 100,  visible: false, type: "string",  headerText: "시설코드", },
          { field: "fcltyName",      textAlign: 'left',   allowEditing: false, width: 200,  visible: true,  type: "string",  headerText: "시설명", },
          { field: "chckPart",       textAlign: 'center', allowEditing: false, width: 100,  visible: true,  type: "string",  headerText: "점검부", isCommonCodeField: true, dataSource: this.commonCodeOptions.fcltyChckPart, },
          { field: "repairDiv",      textAlign: 'center', allowEditing: false, width: 100,  visible: true,  type: "string",  headerText: "수리구분", isCommonCodeField: true, dataSource: this.commonCodeOptions.fcltyRepairPart, },
          { field: "defectRegn",     textAlign: 'center', allowEditing: false, width: 100,  visible: true,  type: "string",  headerText: "고장부위", isCommonCodeField: true, dataSource: this.commonCodeOptions.fcltyDefectRegn, },
        ],
        provides: [ExcelExport, Filter, ForeignKey, Page, Resize, Edit],
        dataSource: this.gridField.dataSource,

        allowResizing: true,
        allowSorting: true,
        allowFiltering: true,
        allowExcelExport: true,
        isShowProgress: true,
        isNOColumnDisplay:true,
        noColumnWidth: 35,
        isSelectedRowRetain: true,
        selectionSettings: {type: "Single", mode: "Row", enableToggle: false, persistSelection: false,},
        allowPaging: true,
        pageSettings: { pageSize: 50 },
        resizeSettings: {mode:"Normal"},
      };
    },
    initGrid2() {
      return {
        columns: [
          { field: "dtlId",        textAlign: 'center', allowEditing: false, width: 30,  visible: false, type: "string",  headerText: "상세ID", isPrimaryKey: true,  },
          { field: "repairId",     textAlign: 'center', allowEditing: false, width: 30,  visible: false, type: "string",  headerText: "수리ID",  },
          { field: "wrhousCode",   textAlign: 'center', allowEditing: false, width: 30,  visible: false, type: "string",  headerText: "창고코드", },
          { field: "wrhousName",   textAlign: 'left',   allowEditing: false, width: 100, visible: true,  type: "string",  headerText: "창고", },
          { field: "suppliesCode", textAlign: 'center', allowEditing: false, width: 100, visible: true,  type: "string",  headerText: "저장품코드", },
          { field: "suppliesName", textAlign: 'left',   allowEditing: false, width: 150, visible: true,  type: "string",  headerText: "저장품", },
          { field: "unit",         textAlign: 'center', allowEditing: false, width: 100, visible: true,  type: "string",  headerText: "단위", },
          { field: "qty",          textAlign: 'right',  allowEditing: true,  width: 100, visible: true,  type: "number",  headerText: "수량",
            isNumericType: true,  inputNumberProperty: {allowDot:true ,maxUnderDotLength: 3 },}, // editType:'numericedit', format: 'N2', // 그리드에서 버튼으로 숫자 조정시 세팅
          { field: "remarks",      textAlign: 'left',   allowEditing: true,  width: 150, visible: true,  type: "string",  headerText: "비고", },
        ],
        provides: [ExcelExport, Filter, ForeignKey, Page, Resize, Edit],
        dataSource: this.gridField2.dataSource,

        allowResizing: true,
        allowSorting: true,
        allowFiltering: true,
        allowExcelExport: true,
        isShowProgress: true,
        isNOColumnDisplay:false,
        noColumnWidth: 35,
        isSelectedRowRetain: true,
        selectionSettings: {type: "Single", mode: "Row", enableToggle: false, persistSelection: false,},
        allowPaging: false,
        pageSettings: { pageSize: 50 },
        //그리드 정합성 체크
        validationRules: {
          wrhousName:   {required: true,},
          suppliesName: {required: true,},
          qty:     {required: true, min:0.1},
          suppliesCode: {
            required: true,
            custom: {
              method: (record) => {
                if(this.$refs.grid2.getBatchCurrentViewRecords().filter(v => v.wrhousCode === record.wrhousCode && v.suppliesCode === record.suppliesCode).length>1) {
                  return;
                }
                return true;
              },
              message: "중복된 창고,저장품이 있습니다.",
            },
          },

        },
      };
    },
  },
  /**
   * ##################################### 화면 시작시 DATA API ########################################
   */
  created() {
    // 기초 데이터 초기화
    this.clearDetailData();
  },
  async mounted() {
    //기초데이터 세팅
    await this.initData();
  },
  /**
   * ##################################### 화면 이벤트 ########################################
   */
  methods: {
    //필수입력체크
    validateFormRefs,
    //기초데이터 세팅
    async initData() {
      //달력 min,max 설정
      const datePickerInstance = this.$refs.repairDate.$refs.datepicker.ej2Instances;
      //datePickerInstance.min = await StockCommon.getLastSuppliesClosingYm();// 최종 마감일자
      datePickerInstance.max = getFormattedDate(new Date());
    },
    //region ******************************* 버튼 이벤트 *******************************
    //조회
    async onClickSearch() {
      await this.onClickClose();

      const args = {
        fcltyName: this.searchFilterOptions.fcltyName,
        fcltyDiv: this.searchFilterOptions.fcltyDiv,
        fromDate: this.searchFilterOptions.dateRange.from,
        toDate: this.searchFilterOptions.dateRange.to,
      };

      this.gridField.dataSource = await StockFacilityMng.getFcltyRepairHdr(args);
    },
    //추가
    onClickAdd() {
      this.isOpen = true;
      this.isNew = true;
      this.clearDetailData();
    },
    //저장
    async onClickSave() {
      //1.필수입력체크
      if (!this.validateFormRefs(this.validateRefField)) {
        return;
      }

      //수리 일자 체크
      if (compareDate(getFormattedDate(new Date()), this.detailData.repairDate) < 0) {
        this.errorToast("수리일자는 미래일자로 등록할 수 없습니다.");
        this.$refs.repairDate.focus();
        return;
      }

      //2. 그리드 필수 입력체크
      if (this.$refs.grid2.isGridModified() && !this.$refs.grid2.validate()) {
        return;
      }

      //3. 저장처리
      const {addedRecords, changedRecords, deletedRecords,} = this.$refs.grid2.getBatchChanges();
      this.detailData.dtlListD = deletedRecords;
      this.detailData.dtlListU = changedRecords;
      this.detailData.dtlListI = addedRecords;

      if (this.isNew) {
        await StockFacilityMng.postFcltyRepairDetail(this.detailData);
      } else {
        await StockFacilityMng.patchFcltyRepairDetail(this.detailData);
      }

      //4. 메세지 호출
      this.infoToast(this.$t('main.popupMessage.saved'));
      await this.onClickSearch();
    },
    //삭제
    async onClickDelete() {
      //1. 확인
      if (!(await this.confirm(this.$t('main.popupMessage.confirmDelete')))) {
        return;
      }

      //2. 삭제처리
      const args = {deleteKey:this.detailData.repairId, deleteDate:this.detailData.repairDate};
      await StockFacilityMng.deleteFcltyRepairDetail(args);

      //3. 메세지 호출
      this.infoToast(this.$t('main.popupMessage.deleted'));
      await this.onClickSearch();
    },
    //엑셀 출력
    onClickExcel() {
      this.$refs.grid.excelExport({fileName: this.gridField.title+".xlsx"});
    },
    //닫기
    async onClickClose() {
      this.isOpen = false;
      await this.clearDetailData();
    },
    //리포트 출력
    onClickPrint() {
      //
    },
    //endregion
    //region ******************************* 상세 이벤트 *******************************
    //수리 상세 조회
    async searchDetail(code) {
      const args = {repairId:code};
      this.detailData = await StockFacilityMng.getFcltyRepairDetail(args);
    },
    //출고품목 조회
    async searchList(code) {
      const args = {repairId:code};
      this.gridField2.dataSource = await StockFacilityMng.getFcltyRepairDtlList(args);
    },
    //출고품목 삭제
    onClickListDel() {
      this.$refs.grid2.deleteRecord();
      //ROW- COUNT
      this.gridField2.count = this.$refs.grid2.getBatchCurrentViewRecords().length;
    },
    //endregion
    //region ******************************* 공통 이벤트 *******************************
    //기초 데이터 초기화
    clearDetailData() {
      this.detailData.repairId = null;
      this.detailData.repairDate = getFormattedDate(new Date());
      this.detailData.fcltyCode = null;
      this.detailData.fcltyName = null;
      this.detailData.fcltyDivName = null;
      this.detailData.chckPart = null;
      this.detailData.repairDiv = null;
      this.detailData.defectRegn = null;
      this.detailData.remarks = null;
      this.detailData.delFlag = false;
      this.detailData.insertName = this.username;
      this.detailData.dtlListD = [];
      this.detailData.dtlListU = [];
      this.detailData.dtlListI = [];
      this.gridField2.dataSource = [];
    },
    //시설 검색 팝업
    onClickPopupSearch() {
      this.isShowSearchPopup = true;
    },
    //시설 검색 팝업 완료
    onPopupConfirmed(args) {
      this.isShowSearchPopup = false;
      if(args != null){
        this.detailData.fcltyCode = args.fcltyCode;
        this.detailData.fcltyName = args.fcltyName;
        this.detailData.fcltyDiv = args.fcltyDiv;
      }
    },
    //출고품목 검색 팝업
    onClickPopupSearch2() {
      this.isShowSearchPopup2 = true;
    },
    //출고품목 검색 팝업 완료
    onPopupConfirmed2(args) {
      this.isShowSearchPopup2 = false;

      if(args !== null){
        for (const data of args) {
          this.$refs.grid2.addRecord({
            dtlId: null,
            repairId: this.detailData.repairId,
            wrhousCode: data.wrhousCode,
            wrhousName: data.wrhousName,
            suppliesCode: data.suppliesCode,
            suppliesName: data.suppliesName,
            unit: data.unit,
            qty: data.dlivyQty,
            oldQty : 0 ,
          });
        }
      }
      //ROW- COUNT
      this.gridField2.count = this.$refs.grid2.getBatchCurrentViewRecords().length;
    },
    //endregion
    //region ******************************* 그리드 이벤트 *******************************
    //그리드 헤더 이미지적용
    onHeaderCellInfo(args){
      const {cell: {column:{field}}, node} = args;
      if(this.$refs.grid.validationRules[field]?.required){
        node.classList.add(this.$t("className.grid.requiredInputHeader"));
      }
    },
    onHeaderCellInfo2(args){
      const {cell: {column:{field}}, node} = args;
      if(this.$refs.grid2.validationRules[field]?.required){
        node.classList.add(this.$t("className.grid.requiredInputHeader"));
      }
    },

    //그리드 셀 이미지 적용
    onQueryCellInfo(args){
      const {column:{field,allowEditing}, cell} = args;
      if(allowEditing && field){
        cell.classList.add(this.$t('className.grid.modifyArea'));
      }
    },
    onQueryCellInfo2(args){
      const {column:{field,allowEditing}, cell} = args;
      if(allowEditing && field){
        cell.classList.add(this.$t('className.grid.modifyArea'));
      }
    },

    //그리드 셀 클릭
    async onGridClick(args){
      //const {column:{field}, rowData} = args;
      //if(field === "fcltyName"){
        this.isOpen = true;
        this.isNew = false;
        //상세 clear
        this.clearDetailData();
        //상세 표시
        await this.searchDetail(args.rowData.repairId);
        //출고품목 표시
        await this.searchList(args.rowData.repairId);
        this.$refs["fcltyName"].$el.focus();
      //}
    },
    //그리드 조회 완료시(카운트)
    gridComplete(){
      this.gridField.count = this.$refs.grid.getGridBatchCount();
    },
    gridComplete2(){
      this.gridField2.count = this.$refs.grid2.getGridBatchCount();
    },
  }
};
</script>

<!-- ##################################### CUSTOM STYLE ########################################* -->
<style scoped>
body .appContent .body-article .article-left,body .appContent .body-article .article-right{transition:all .3s ease-out}
body .appContent .body-article .article-left{width:100%;}
body .appContent .body-article .article-right{margin-right: calc(-50% - 20px)}
body .appContent .body-article.detail-view-opened .article-left{width:calc(50% - 0px)}
body .appContent .body-article.detail-view-opened .article-right{width:calc(50% - 0px); margin:0}
body .body-footer .section-header {display: block;position: relative;margin: 0px 0px;}
.voc-section {width: 100% !important;}

</style>
